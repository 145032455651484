import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../api/contracts";
import BigNumber from "bignumber.js"
import {
    Button,
    message,
    AutoComplete,
    Switch,
    Select,
    Form,
    Empty,
    Pagination, Input, Modal
} from 'antd';

import {getContractByName, getContractByContract} from "../../api/connectContract";
import {dealMethod, dealPayMethod, viewMethod} from "../../utils/contractUtil"

import {useLocation, useNavigate} from "react-router-dom";
import judgeStatus from "../../utils/judgeStatus";

import sc from "../../imgs/sc.png"
import cut from "../../imgs/remove.png"
import add from "../../imgs/add.png"
import NFTAdminStyle from './NFTAdminStyle';
import {USDTDecimals} from "../../config/constants";
import addressMap from "../../api/addressMap";


const NFTAdmin = (props) => {
    let {state, dispatch} = useConnect();
    const location = useLocation()
    const [pageCount, setPageCount] = useState(10)
    const [curPage, setCurPage] = useState(1)

    const [isDelMolOpen, setDelOpen] = useState(false)

    const [curDelAddr, setCurDelAddr] = useState()

    const [curLevel, setCurLevel] = useState()
    const [isAddMolOpen, setAddOpen] = useState(false)

    const [isDelRecMolOpen, setDelRecMolOpen] = useState(false)

    const [curDelRec, setCurDelRec] = useState()

    const [isAddL2MolOpen, setAddL2Open] = useState(false)
    const [isAddL3MolOpen, setAddL3Open] = useState(false)
    const [value, setValue] = useState(0)
    const [isUpdateOpen, setIsUpdateOpen] = useState(false)
    const [curUpdateAddress, setCurUpdateAddress] = useState()

    const history = useNavigate();
    const [form] = Form.useForm();

    const [total, setTotal] = useState(0)

    const [whitelistArr, setWhiteListArr] = useState([])

    const [inWhitelistArr, setInWhitelistArr] = useState([])

    const [adminArr, setAdminArr] = useState([])
    const [L3owner, setL3Owner] = useState()
    const [receiverArr, setReiverArr] = useState([])
    const [mintStatus, setMintStatus] = useState()
    const [totalRatioR, setRTotalRatio] = useState(0)
    const [totalRatio, setTotalRatio] = useState(0)
    const [totalRefRatio, setTotalRefRatio] = useState(0)
    const [AAAFdaoPointRatioArr, setAAAFdaoPointRatioValue] = useState([])

    const [baseURI, setBaseURIData] = useState()
    const [pointBalance, setPointBalance] = useState(0)

    const [L2SetL3Amount, setL2SetL3Amount] = useState()
    const [owner, setOwner] = useState("")
    const [initAmount, setInitAmount] = useState()
    const [mintedArr, setMintedArr] = useState([])
    const [AAAFdaoPointValue,setAAAFdaoPointValue] = useState([0])
    const [LevelReceiverRatio, set4LevelReceiverRatio] = useState({})
    const [refReceiverRatio, setRefReceiverRatio] = useState({})


    const [whiteListCanMintAmount, setwhiteListCanMintAmount] = useState()
    const [l2whiteListCanMintAmount, setl2whiteListCanMintAmount] = useState()

    const [l3whiteListCanMintAmount, setl3whiteListCanMintAmount] = useState()
    const [addWhiteArr, setAddWArr] = useState([{}])
    const [addL2Arr, setL2WArr] = useState([{}])
    const [addL3Arr, setL3WArr] = useState([{}])

    const [L2AdminArr, setL2AdminArr] = useState([])
    const [L3AdminArr, setL3AdminArr] = useState([])
    const [L2AdminArr1, setL2AdminArr1] = useState([])
    const [L2AdminArr2, setL2AdminArr2] = useState([])

    const [addRecArr, setAddRecArr] = useState([{}])
    const addOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.push({})
        setAddWArr(addWhiteArrT)
    }

    const removeOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.shift()
        setAddWArr(addWhiteArrT)
    }
    const addOneReceiver = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addRecArr))
        addWhiteArrT.push({})
        setAddRecArr(addWhiteArrT)
    }
    const removeReceiver = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addRecArr))
        addWhiteArrT.shift()
        setAddRecArr(addWhiteArrT)
    }
    const addOneL2 = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addL2Arr))
        addWhiteArrT.push({})
        setL2WArr(addWhiteArrT)
    }
    const removeOneL2 = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addL2Arr))
        addWhiteArrT.shift()
        setL2WArr(addWhiteArrT)
    }

    const addOneL3 = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addL3Arr))
        addWhiteArrT.push({})
        setL3WArr(addWhiteArrT)
    }
    const removeOneL3 = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addL3Arr))
        addWhiteArrT.shift()
        setL3WArr(addWhiteArrT)
    }
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("MintSOL", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("MintSOL", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }
    const setrecommendRatio = async () => {
        await handleDealMethod("setrecommendRatio", [form.getFieldValue().recommendRatio * 100])
        get4LevelReceiverRatio()

    }
    const setrecommendRatioA = async () => {
        await handleDealMethod("setrecommendRatioA", [form.getFieldValue().recommendRatioA * 100])
        get4LevelReceiverRatio()
    }
    const setrecommendRatioB = async () => {
        await handleDealMethod("setrecommendRatioB", [form.getFieldValue().recommendRatioB * 100])
        get4LevelReceiverRatio()

    }
    const setRefRatio = async () => {
        await handleDealMethod("setrecommendRatioC", [form.getFieldValue().recommendRatioC * 100])
        get4LevelReceiverRatio()

    }
    const setrecommendRatioC = async () => {
        await handleDealMethod("setrecommendRatioC", [form.getFieldValue().recommendRatioC * 100])
        get4LevelReceiverRatio()

    }
    const setInviteRatio = async () => {
        await handleDealMethod("setInviteRatio", [form.getFieldValue().inviteRatioOne * 100, form.getFieldValue().inviteRatioTwo * 100])
        getRefReceiverRatio()
    }
    const setmintStatus = async () => {

        await handleDealMethod("setmintStatus", [])
        getMintStatus()
    }
    const handlesetAAAFdaoPointValue= async () => {
        await handleDealMethod("setAAAFdaoPointValue", [BigNumber(form.getFieldValue().AAAFdaoPointValue).multipliedBy(10**18).toFixed(0)])
        getAAAFdaoPointValue()
    }
    const setclassToRatio = async () => {
        let tempArr = [
            BigNumber(form.getFieldValue().L1AdminRatio).multipliedBy(100).toFixed(0),
            BigNumber(form.getFieldValue().L2AdminRatio).multipliedBy(100).toFixed(0),
            BigNumber(form.getFieldValue().L3AdminRatio).multipliedBy(100).toFixed(0),
            BigNumber(form.getFieldValue().L4AdminRatio).multipliedBy(100).toFixed(0),
            BigNumber(form.getFieldValue().InviteRadioOne).multipliedBy(100).toFixed(0),
            BigNumber(form.getFieldValue().InviteRadioTwo).multipliedBy(100).toFixed(0),
            BigNumber(form.getFieldValue().InviteRadioOwner).multipliedBy(100).toFixed(0)
        ]
        await handleDealMethod("setclassToRatio", [[0,1,2,3,4,5,6],tempArr])
        getAAAFdaoPointRatio()
    }
    const setReceiver = async () => {
        let arr = [], arr2 = []
        for (let i = 0; i < addRecArr.length; i++) {
            arr.push(form.getFieldValue()["address" + i])
            arr2.push(BigNumber(form.getFieldValue()["rate" + i]).multipliedBy(100).toFixed(0))
        }
        console.log(arr, arr2)
        await handleDealMethod("setReceiver", [arr, arr2])
        getReceiver()
    }
    const setRecRadio = async () => {

        await handleDealMethod("setreceiverRatio", [form.getFieldValue().RecAddress, BigNumber(form.getFieldValue().RecRadio).multipliedBy(100).toFixed(0)])
        getReceiver()
    }
    const setBaseURI = async () => {

        await handleDealMethod("setBaseURI", [form.getFieldValue().BaseURI])
        getBaseUrl()
    }
    const depositAmount = async () => {
        let contractTemp = await getContractByName("Point", state.api,)
        await dealMethod(contractTemp, state.account, "transfer", [addressMap["MintSOL"].address, BigNumber(form.getFieldValue().depositAmount).multipliedBy(10**18).toFixed(0)])
        getPointBalance()
    }
    const withdrawAmount = async () => {
        await handleDealMethod("backPoint", [])
        getPointBalance()
    }
    const setadminL3AddWlAmount = async () => {
        await handleDealMethod("setadminL3AddWlAmount", [form.getFieldValue().L3Amount])
        getL3AMount()
    }
    const setAdminL3Amount = async () => {
        await handleDealMethod("setAdminL3Amount", [form.getFieldValue().adminL3Amount])
        getL3AMount()
    }

    const transferOwnership = async () => {

        await handleDealMethod("transferOwnership", [form.getFieldValue().address])
        getOwner()
    }
    const getOwner = async () => {
        const Admin = await handleViewMethod("owner", [])
        setOwner(Admin)
    }
    const getPointBalance = async () => {
        let contractTemp = await getContractByName("Point", state.api,)
        const res =
            await viewMethod(contractTemp, state.account, "balanceOf", [addressMap["MintSOL"].address])

        setPointBalance(BigNumber(res).div(10**18).toString())
    }
    const get4LevelReceiverRatio = async () => {
        const recommendRatio = await handleViewMethod("recommendRatio", [])
        const recommendRatioA = await handleViewMethod("recommendRatioA", [])
        const recommendRatioB = await handleViewMethod("recommendRatioB", [])
        const recommendRatioC = await handleViewMethod("recommendRatioC", [])
        set4LevelReceiverRatio({
            recommendRatio: BigNumber(recommendRatio).div(100).toFixed(0),
            recommendRatioA: BigNumber(recommendRatioA).div(100).toFixed(0),
            recommendRatioB: BigNumber(recommendRatioB).div(100).toFixed(0),
            recommendRatioC: BigNumber(recommendRatioC).div(100).toFixed(0),
        })

        setTotalRatio(BigNumber(recommendRatio).plus(recommendRatioA).plus(recommendRatioB).plus(recommendRatioC).div(100).toFixed(0))
    }
    const getRefReceiverRatio = async () => {
        const inviteRatioOne = await handleViewMethod("inviteRatioOne", [])
        const inviteRatioTwo = await handleViewMethod("inviteRatioTwo", [])
        setRefReceiverRatio({
            inviteRatioOne: BigNumber(inviteRatioOne).div(100).toFixed(0),
            inviteRatioTwo: BigNumber(inviteRatioTwo).div(100).toFixed(0),
        })
        setTotalRefRatio(BigNumber(inviteRatioOne).plus(inviteRatioTwo).div(100).toFixed(0))
    }
    const getBaseUrl = async () => {
        const res = await handleViewMethod("baseURI", [])
        setBaseURIData(res)
    }
    const getAAAFdaoPointValue = async () => {
        const res = await handleViewMethod("AAAFdaoPointValue", [])
        setAAAFdaoPointValue(BigNumber(res).div(10**18))
    }
    const getMintStatus = async () => {
        const res = await handleViewMethod("mintStatus", [])
        setMintStatus(res)
    }
    const getAAAFdaoPointRatio = async () => {
        let resArr = []
        for(let i=0;i<7;i++){
            const res = await handleViewMethod("classToRatio", [i])
            resArr.push(res)
        }
        setAAAFdaoPointRatioValue(resArr)
    }
    const getReceiver = async () => {
        const length = await handleViewMethod("getReceiverLength", [])
        let arr = [], totalRatio = 0
        for (let i = 0; i < length; i++) {
            const address = await handleViewMethod("receiver", [i])

            const radio = await handleViewMethod("receiverRatio", [address])
            totalRatio = BigNumber(totalRatio).plus(radio)
            arr.push({
                address,
                radio
            })
        }
        if (totalRatio) {
            setRTotalRatio(totalRatio.div(100))
        }
        setReiverArr(arr)
    }


    const getL3List = async () => {
        const res = await handleViewMethod("getL3List", [])
        setL3AdminArr(res)
    }
    const getL2List = async () => {
        const arr = await handleViewMethod("getL2List", [])
        setL2AdminArr(arr)
    }
    const getWhiteListCanMintAmount = async () => {
        const res = await handleViewMethod("whiteListCanMintAmount", [])
        setwhiteListCanMintAmount(res)
    }
    const getValue = async () => {
        const res = await handleViewMethod("value", [])
        setValue(res)
    }
    const getl2WhiteListCanMintAmount = async () => {
        const res = await handleViewMethod("l2whiteListCanMintAmount", [])
        setl2whiteListCanMintAmount(res)
    }
    const getl3WhiteListCanMintAmount = async () => {
        const res = await handleViewMethod("l3whiteListCanMintAmount", [])
        setl3whiteListCanMintAmount(res)
    }
    const getL3AMount = async () => {
        const Admin = await handleViewMethod("adminL3AddWlAmount", [])
        setL3Owner(Admin)
    }
    const getsetAdminL3Amount = async () => {
        const res = await handleViewMethod("adminL3AdminAmount", [])
        setL2SetL3Amount(res)
    }
    const getAdmin = async () => {

        let adminArr = []
        for (let i = 0; i < 7; i++) {
            const Admin = await handleViewMethod("owner", [])
            adminArr.push(Admin)
        }
        setAdminArr(adminArr)
    }
    const getInitAmount = async () => {
        let initamount = 0, amount = 0
        initamount = await handleViewMethod("initAmount", [])
        amount = await handleViewMethod("totalMint", [])
        setInitAmount(initamount)
        setMintedArr(amount)

    }
    const getWiteList = async () => {
        let tempArr = [], whiteArr = [], whiteArr1 = []
        for (let i = 0; i < 7; i++) {
            whiteArr = await handleViewMethod("getWiteList", [])

            const inW1 = whiteArr.some(item => {
                return item.toLowerCase() == state.account.toLowerCase()
            })
            tempArr.push(inW1)
        }
        setWhiteListArr(whiteArr)
        setTotal(whiteArr.length)
        setInWhitelistArr(tempArr)

    }

    const handlesetValue = async () => {
        await handleDealMethod("setValue", [BigNumber(form.getFieldValue().Value).multipliedBy(10 ** USDTDecimals).toFixed(0)])
        getValue()
    }
    const handlesetwhiteListCanMintAmount = async () => {
        await handleDealMethod("setwhiteListCanMintAmount", [form.getFieldValue().whiteListCanMintAmount])
        getWhiteListCanMintAmount()
    }
    const handleSetInitAmount = async () => {
        await handleDealMethod("setInitAmount", [form.getFieldValue().InitAmount])
        getInitAmount()
    }

    const addWhiteListUser = async () => {
        let arr = []
        for (let i = 0; i < addWhiteArr.length; i++) {
            arr.push(form.getFieldValue()["address" + i])
        }
        await handleDealMethod("addWhiteListUser", [arr])
        getWiteList()
    }

    const onChangePage = async (page) => {
        await setCurPage(page)
    }
    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
    }
    const setAdminLevel2 = async () => {
        let arr = []
        for (let i = 0; i < addL2Arr.length; i++) {
            arr.push(form.getFieldValue()["address" + i])
        }
        console.log(arr)
        await handleDealMethod("setAdminLevel2", [arr])
        getL2List()
    }

    const setAdminLevel3 = async () => {
        let arr = []
        for (let i = 0; i < addL3Arr.length; i++) {
            arr.push(form.getFieldValue()["address" + i])
        }
        await handleDealMethod("setAdminLevel3", [arr, true])
        getL3List()
    }

    const removeFromWhiteList = async () => {
        await handleDealMethod("removeFromWhiteList", [[curDelAddr]])
        getWiteList()
    }

    const unsetReceiver = async () => {
        await handleDealMethod("unsetReceiver", [curDelRec])
        getReceiver()
    }
    const updateL2Address = async () => {
        let arr = JSON.parse(JSON.stringify(L2AdminArr))
        let updateArr1 = JSON.parse(JSON.stringify(L2AdminArr1)), updateArr2 = JSON.parse(JSON.stringify(L2AdminArr2))
        if (arr && arr.length > 0) {
            for (let i = 0; i < L2AdminArr.length; i++) {
                if (arr[i].toLowerCase() == curUpdateAddress.toString().toLowerCase()) {
                    updateArr1[i] = form.getFieldValue().fundaddress1
                    updateArr2[i] = form.getFieldValue().fundaddress2
                }
            }
        }
        console.log([arr, updateArr1, updateArr2])
        await handleDealMethod("setAdminl2Receiver", [arr, updateArr1, updateArr2])
        getReceiver()
        getL3List()
    }
    useEffect(async () => {

        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        getMintStatus()

        getOwner()
        getAdmin()
        getInitAmount()
        // getWiteList()
        // getL3AMount()
        getBaseUrl()
        // getWhiteListCanMintAmount()
        // getl2WhiteListCanMintAmount()
        // getl3WhiteListCanMintAmount()
        getL3List()
        getL2List()
        getValue()
        getReceiver()
        getsetAdminL3Amount()
        get4LevelReceiverRatio()
        getRefReceiverRatio()
        getPointBalance()
        getAAAFdaoPointRatio()
        getAAAFdaoPointValue()
    }, [state.account])


    return (

        <NFTAdminStyle>
            <div className="part1">
                <Modal className="model-dialogdel" title="Update fund address" open={isUpdateOpen}
                       onOk={() => {
                           updateL2Address()
                       }}
                       onCancel={() => {
                           setIsUpdateOpen(false)
                       }}>
                    <p>
                        Update L2 Fund Address
                    </p>
                    <div className="value">
                        {curUpdateAddress}
                    </div>
                    <Form form={form} name="control-hooks" className="form">

                        <Form.Item
                            name="fundaddress1"
                            label="Fund Address1"
                            validateTrigger="onBlur"
                            validateFirst={true}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="fundaddress2"
                            label="Fund Address2"
                            validateTrigger="onBlur"
                            validateFirst={true}
                        >
                            <Input/>
                        </Form.Item>

                    </Form>
                </Modal>
                <Modal className="model-dialogdel" title="Delete" open={isDelRecMolOpen}
                       onOk={() => {
                           unsetReceiver()
                       }}
                       onCancel={() => {
                           setDelRecMolOpen(false)
                       }}>
                    <p>
                        Wallet Address
                    </p>
                    <div className="value">
                        {curDelRec}
                    </div>
                </Modal>
                <div className="panel-box">
                    <div className="panel-container">
                        <div className='panel-title'>
                            Transfer Administrator
                        </div>
                        <Form form={form} name="control-hooks" className="form">
                            <Form.Item
                                label="Administrator Address"
                            >
                                {owner}
                            </Form.Item>
                            <Form.Item
                                name="address"
                                label="New Address"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Button type="primary" className="go-btn" onClick={() => {
                                transferOwnership()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>
                </div>
                <div className="panel-box">
                    <div className="panel-container">
                        <div className='panel-title'>
                            Set Price
                        </div>
                        <Form form={form} name="control-hooks" className="form">
                            <Form.Item
                                label="price"
                            >
                                {BigNumber(value).dividedBy(10 ** USDTDecimals).toFixed(0)}
                            </Form.Item>
                            <Form.Item
                                name="Value"
                                label="New Price"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Button type="primary" className="go-btn" onClick={() => {
                                handlesetValue()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>
                </div>
                <div className="panel-box">
                    <div className="panel-container">
                        <div className='panel-title'>
                            Set NFT Amounts
                        </div>

                        <Form form={form} name="control-hooks" className="form">
                            <Form.Item
                                label="Amounts"
                            >
                                <span>
                                    {initAmount}
                                </span>
                            </Form.Item>
                            <Form.Item
                                name="InitAmount"
                                label="New Amounts"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Button type="primary" className="go-btn" onClick={() => {
                                handleSetInitAmount()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>


                </div>
                {/*<div className="panel-box">*/}
                {/*    <div className="panel-container">*/}
                {/*        <div className='panel-title'>*/}
                {/*            whiteList Can MintAmount( {whiteListCanMintAmount})*/}
                {/*        </div>*/}
                
                
                {/*        /!*<div className='panel-title'>*!/*/}
                {/*        /!*     L2 whiteList Can MintAmount(  {l2whiteListCanMintAmount})*!/*/}
                {/*        /!*</div>*!/*/}
                
                
                {/*        /!*<div className='panel-title'>*!/*/}
                {/*        /!*     L3 whiteList Can MintAmount         (  {l3whiteListCanMintAmount})*!/*/}
                {/*        /!*</div>*!/*/}
                
                {/*        <Form form={form} name="control-hooks" className="form">*/}
                
                {/*            <Form.Item*/}
                {/*                name="whiteListCanMintAmount"*/}
                {/*                label="New Amounts"*/}
                {/*                validateTrigger="onBlur"*/}
                {/*                validateFirst={true}*/}
                {/*            >*/}
                {/*                <Input/>*/}
                {/*            </Form.Item>*/}
                {/*            /!*<Form.Item*!/*/}
                {/*            /!*    name="whiteListCanMintAmount2"*!/*/}
                {/*            /!*    label="New Amounts"*!/*/}
                {/*            /!*    validateTrigger="onBlur"*!/*/}
                {/*            /!*    validateFirst={true}*!/*/}
                {/*            /!*>*!/*/}
                {/*            /!*    <Input/>*!/*/}
                {/*            /!*</Form.Item>*!/*/}
                {/*            /!*<Form.Item*!/*/}
                {/*            /!*    name="whiteListCanMintAmount3"*!/*/}
                {/*            /!*    label="New Amounts"*!/*/}
                {/*            /!*    validateTrigger="onBlur"*!/*/}
                {/*            /!*    validateFirst={true}*!/*/}
                {/*            /!*>*!/*/}
                {/*            /!*    <Input/>*!/*/}
                {/*            /!*</Form.Item>*!/*/}
                {/*            <Button type="primary" className="go-btn" onClick={() => {*/}
                {/*                handlesetwhiteListCanMintAmount()*/}
                {/*            }}>*/}
                {/*                Confirm*/}
                {/*            </Button>*/}
                {/*        </Form>*/}
                {/*    </div>*/}
                
                {/*</div>*/}

                <Modal className="model-dialogdel" title="Delete" open={isDelMolOpen}
                       onOk={() => {
                           removeFromWhiteList()
                       }}
                       onCancel={() => {
                           setDelOpen(false)
                       }}>
                    <p>
                        Wallet Address
                    </p>
                    <div className="value">
                        {curDelAddr}
                    </div>
                </Modal>


                <div className='panel-box'>
                    <div className="panel-container">
                        <div className='panel-title'>
                            setmintStatus({mintStatus ? "open" : "close"})
                        </div>
                        <Form form={form} name="control-hooks" className="form">

                            <Button type="primary" className="go-btn" onClick={() => {
                                setmintStatus()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>
                    <div className="panel-container">
                        <div className='panel-title'>
                            Add Receiver
                        </div>

                        <Form form={form} name="control-hooks" className="form">

                            {addRecArr.map((item, index) => {
                                return (
                                    <div style={{display: "flex"}}>
                                        <Form.Item
                                            name={"address" + index}
                                            validateTrigger="onBlur"
                                            label="Address"
                                            validateFirst={true}
                                        >
                                            <div className="input-box">
                                                <Input type="text"></Input>
                                            </div>
                                        </Form.Item>
                                        <Form.Item
                                            name={"rate" + index}
                                            validateTrigger="onBlur"
                                            label="Rate"
                                            validateFirst={true}
                                        >
                                            <div className="input-box">
                                                <Input type="text"></Input>
                                            </div>
                                        </Form.Item>
                                    </div>
                                )
                            })}
                            <div className="icon-box">
                                <svg onClick={() => {
                                    addOneReceiver()
                                }} t="1679715594436" className="icon" viewBox="0 0 1024 1024" version="1.1"
                                     xmlns="http://www.w3.org/2000/svg" p-id="2724" width="30" height="30">
                                    <path
                                        d="M512 1024C229.248 1024 0 794.752 0 512S229.248 0 512 0s512 229.248 512 512-229.248 512-512 512z m0-896C299.968 128 128 299.968 128 512s171.968 384 384 384 384-171.968 384-384S724.032 128 512 128z m192 448h-128v128c0 35.392-28.608 64-64 64a64 64 0 0 1-64-64v-128h-128a64 64 0 1 1 0-128h128v-128a64.021333 64.021333 0 0 1 128 0v128h128a64 64 0 0 1 64 64c0 35.392-28.608 64-64 64z"
                                        fill="#ffffff" p-id="2725"></path>
                                </svg>
                                <svg onClick={() => {
                                    removeReceiver()
                                }} t="1679716770324" className="icon" viewBox="0 0 1024 1024" version="1.1"
                                     xmlns="http://www.w3.org/2000/svg" p-id="3771" width="30" height="30">
                                    <path
                                        d="M512 1024C229.248 1024 0 794.752 0 512S229.248 0 512 0s512 229.248 512 512-229.248 512-512 512z m0-896C299.968 128 128 299.968 128 512s171.968 384 384 384 384-171.968 384-384S724.032 128 512 128z m192 448H320a64 64 0 1 1 0-128h384a64 64 0 0 1 64 64c0 35.392-28.608 64-64 64z"
                                        fill="#ffffff" p-id="3772"></path>
                                </svg>
                            </div>
                        </Form>
                        <Button type="primary" className="go-btn" onClick={() => {
                            setReceiver()
                        }}>
                            Confirm
                        </Button>
                    </div>
                    <div className="panel-container">
                        <div className='panel-title'>
                            Receiver Radio Arr
                        </div>
                        <div className="fire-list-box">

                            <div className="list-header">
                                <div className="col" style={{width: "360px", justifyContent: "center"}}>
                                    <div style={{width: "360px", justifyContent: "center"}}>
                                        Address
                                    </div>
                                </div>
                                <div className="col" style={{width: "20%", justifyContent: "center"}}>
                                    Ratio
                                </div>
                                <div className="col">
                                    Delete
                                </div>
                            </div>
                            <div className="list-item">
                                <div className="col address" style={{width: "360px", justifyContent: "center"}}>
                                    <div style={{width: "360px", justifyContent: "center"}}>

                                        L1 Admin Ratio
                                    </div>
                                </div>
                                <div className="col">
                                    {LevelReceiverRatio.recommendRatio}
                                </div>
                                <div className="col">

                                </div>
                            </div>
                            <div className="list-item">
                                <div className="col address" style={{width: "360px", justifyContent: "center"}}>
                                    <div style={{width: "360px", justifyContent: "center"}}>

                                        L2 Admin Ratio
                                    </div>
                                </div>
                                <div className="col">
                                    {LevelReceiverRatio.recommendRatioA}
                                </div>
                                <div className="col">

                                </div>
                            </div>

                            <div className="list-item">
                                <div className="col address" style={{width: "360px", justifyContent: "center"}}>
                                    <div style={{width: "360px", justifyContent: "center"}}>
                                        L3 Admin Ratio
                                    </div>
                                </div>
                                <div className="col">
                                    {LevelReceiverRatio.recommendRatioB}
                                </div>
                                <div className="col">

                                </div>
                            </div>
                            <div className="list-item">
                                <div className="col address" style={{width: "360px", justifyContent: "center"}}>
                                    <div style={{width: "360px", justifyContent: "center"}}>
                                        L4 Admin Ratio
                                    </div>
                                </div>
                                <div className="col">
                                    {LevelReceiverRatio.recommendRatioC}
                                </div>
                                <div className="col">

                                </div>
                            </div>
                            <div className="list-item">
                                <div className="col address" style={{width: "360px", justifyContent: "center"}}>
                                    <div style={{width: "360px", justifyContent: "center"}}>
                                        Invite Radio One
                                    </div>
                                </div>
                                <div className="col">
                                    {refReceiverRatio.inviteRatioOne}
                                </div>
                                <div className="col">

                                </div>
                            </div>
                            <div className="list-item">
                                <div className="col address" style={{width: "360px", justifyContent: "center"}}>
                                    <div style={{width: "360px", justifyContent: "center"}}>
                                        Invite Radio Two
                                    </div>
                                </div>
                                <div className="col">
                                    {refReceiverRatio.inviteRatioTwo}
                                </div>
                                <div className="col">

                                </div>
                            </div>
                            {receiverArr.map((item, index) => {
                                return (<div className='list-item' key={index}>

                                    <div className='col address' style={{width: "360px", justifyContent: "center"}}>
                                        <div style={{width: "360px", justifyContent: "center"}}>
                                            {item.address}
                                        </div>
                                    </div>
                                    <div className='col radio' style={{justifyContent: "center"}}>
                                        {BigNumber(item.radio).div(100).toFixed(0)}
                                    </div>
                                    <div className="col sc1">
                                        <img src={sc} className="sc" id='scc' onClick={() => {
                                            setDelRecMolOpen(true)
                                            setCurDelRec(item.address)
                                        }}/>
                                    </div>

                                </div>)
                            })}
                            <div className="list-item">
                                <div className="col address" style={{width: "360px", justifyContent: "center"}}>
                                    <div style={{width: "360px", justifyContent: "center"}}>
                                        Total
                                    </div>
                                </div>

                                <div className="col">
                                    {BigNumber(totalRatio).plus(totalRatioR).plus(totalRefRatio).toFixed(0)}
                                </div>
                                <div className="col">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-container">
                        <div className='panel-title'>
                            Update ReceiverRatio
                        </div>

                        <Form form={form} name="control-hooks" className="form">
                            <Form.Item
                                name="RecAddress"
                                label="RecAddress"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="RecRadio"
                                label="RecRadio"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Button type="primary" className="go-btn" onClick={() => {
                                setRecRadio()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>

                    <div className="panel-container">
                        <div className='panel-title'>
                            setL1ReceiverRatio ({LevelReceiverRatio.recommendRatio})
                        </div>

                        <Form form={form} name="control-hooks" className="form">

                            <Form.Item
                                name="recommendRatio"
                                label="recommendRatio"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Button type="primary" className="go-btn" onClick={() => {
                                setrecommendRatio()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>
                    <div className="panel-container">
                        <div className='panel-title'>
                            setL2ReceiverRatio({LevelReceiverRatio.recommendRatioA})
                        </div>

                        <Form form={form} name="control-hooks" className="form">

                            <Form.Item
                                name="recommendRatioA"
                                label="recommendRatio"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Button type="primary" className="go-btn" onClick={() => {
                                setrecommendRatioA()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>
                    <div className="panel-container">
                        <div className='panel-title'>
                            setL3ReceiverRatio({LevelReceiverRatio.recommendRatioB})
                        </div>

                        <Form form={form} name="control-hooks" className="form">

                            <Form.Item
                                name="recommendRatioB"
                                label="recommendRatio"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Button type="primary" className="go-btn" onClick={() => {
                                setrecommendRatioB()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>
                    <div className="panel-container">
                        <div className='panel-title'>
                            setL4ReceiverRatio({LevelReceiverRatio.recommendRatioC})
                        </div>

                        <Form form={form} name="control-hooks" className="form">

                            <Form.Item
                                name="recommendRatioC"
                                label="recommendRatio"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Button type="primary" className="go-btn" onClick={() => {
                                setrecommendRatioC()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>

                    <div className="panel-container">
                        <div className='panel-title'>

                            set Invite ReceiverRatio({refReceiverRatio.inviteRatioOne},{refReceiverRatio.inviteRatioTwo})
                        </div>
                        <Form form={form} name="control-hooks" className="form">

                            <Form.Item
                                name="inviteRatioOne"
                                label="inviteRatioOne"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="inviteRatioTwo"
                                label="inviteRatioTwo"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Button type="primary" className="go-btn" onClick={() => {
                                setInviteRatio()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>
                    <div className="panel-container">
                        <div className='panel-title'>
                          set FdaoPoint Price ({AAAFdaoPointValue.toString()})
                        </div>
                        <Form form={form} name="control-hooks" className="form">

                            <Form.Item
                                name="AAAFdaoPointValue"
                                label="AAAFdaoPointValue"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>

                            <Button type="primary" className="go-btn" onClick={() => {
                                handlesetAAAFdaoPointValue()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>

                    <div className="panel-container">
                        <div className='panel-title'>
                            set Point ReceiverRatio
                        </div>
                        {AAAFdaoPointRatioArr.map((item,index)=>{
                            return
                        })}
                        <div className="fire-list-box">
                            <div className="list-item">
                                <div className="col">
                                    L1 Admin Ratio
                                </div>
                                <div className="col">
                                    {AAAFdaoPointRatioArr?BigNumber(AAAFdaoPointRatioArr[0]).div(100).toFixed(0):""}
                                </div>
                            </div>
                            <div className="list-item">
                                <div className="col">
                                    L2 Admin Ratio
                                </div>
                                <div className="col">
                                    {AAAFdaoPointRatioArr?BigNumber(AAAFdaoPointRatioArr[1]).div(100).toFixed(0):""}
                                </div>
                            </div>
                            <div className="list-item">
                                <div className="col">
                                    L3 Admin Ratio
                                </div>
                                <div className="col">
                                    {AAAFdaoPointRatioArr?BigNumber(AAAFdaoPointRatioArr[2]).div(100).toFixed(0):""}
                                </div>
                            </div>
                            <div className="list-item">
                                <div className="col">
                                    L4 Admin Ratio
                                </div>
                                <div className="col">
                                    {AAAFdaoPointRatioArr?BigNumber(AAAFdaoPointRatioArr[3]).div(100).toFixed(0):""}
                                </div>
                            </div>

                            <div className="list-item">
                                <div className="col">
                                    Invite Radio One
                                </div>
                                <div className="col">
                                    {AAAFdaoPointRatioArr?BigNumber(AAAFdaoPointRatioArr[4]).div(100).toFixed(0):""}
                                </div>
                            </div>
                            <div className="list-item">
                                <div className="col">
                                    Invite Radio Two
                                </div>
                                <div className="col">
                                    {AAAFdaoPointRatioArr?BigNumber(AAAFdaoPointRatioArr[5]).div(100).toFixed(0):""}
                                </div>
                            </div>
                            <div className="list-item">
                                <div className="col">
                                    Invite Radio Owner
                                </div>
                                <div className="col">
                                    {AAAFdaoPointRatioArr?BigNumber(AAAFdaoPointRatioArr[6]).div(100).toFixed(0):""}
                                </div>
                            </div>
                        </div>
                        <Form form={form} name="control-hooks" className="form">

                            <Form.Item
                                name="L1AdminRatio"
                                label="L1 Admin Ratio"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="L2AdminRatio"
                                label="L2 Admin Ratio"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="L3AdminRatio"
                                label="L3 Admin Ratio"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="L4AdminRatio"
                                label="L4 Admin Ratio"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="InviteRadioOne"
                                label="Invite Radio One"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="InviteRadioTwo"
                                label="Invite Radio Two"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="InviteRadioOwner"
                                label="Invite Radio Owner"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Button type="primary" className="go-btn" onClick={() => {
                                setclassToRatio()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>
                    <div className='panel-container1'>
                        <Modal className="model-dialogadd" title="Add" open={isAddMolOpen}
                               onOk={() => {
                                   addWhiteListUser()
                               }}
                               onCancel={() => {
                                   setAddOpen(false)
                               }}>
                            <Form form={form} name="control-hooks" className="form">
                                {addWhiteArr.map((item, index) => {
                                    return (
                                        <Form.Item
                                            name={"address"
                                                + index
                                            }
                                            validateTrigger="onBlur"
                                            label="Address"
                                            validateFirst={true}
                                        >
                                            <div className="input-box">
                                                <Input type="text"></Input>
                                            </div>
                                        </Form.Item>
                                    )
                                })}
                                <div className="icon-box">
                                    <img width={30} src={add} onClick={() => {
                                        addOneWhite()
                                    }}/>
                                    <img width={30} src={cut} onClick={() => {
                                        removeOneWhite()
                                    }}/>

                                </div>
                            </Form>
                        </Modal>
                        <Modal className="model-dialogadd" title="Add" open={isAddL2MolOpen}
                               onOk={() => {
                                   setAdminLevel2()
                               }}
                               onCancel={() => {
                                   setAddL2Open(false)
                               }}>
                            <Form form={form} name="control-hooks" className="form">
                                {addL2Arr.map((item, index) => {
                                    return (
                                        <>
                                            <h4 style={{color: "white"}}>
                                                Account {index + 1}
                                            </h4>
                                            <Form.Item
                                                name={"address"
                                                    + index
                                                }
                                                validateTrigger="onBlur"
                                                label="Address"
                                                validateFirst={true}
                                            >
                                                <div className="input-box">
                                                    <Input type="text"></Input>
                                                </div>
                                            </Form.Item>
                                        </>
                                    )
                                })}
                                <div className="icon-box">
                                    <img width={30} src={add} onClick={() => {
                                        addOneL2()
                                    }}/>
                                    <img width={30} src={cut} onClick={() => {
                                        removeOneL2()
                                    }}/>

                                </div>
                            </Form>
                        </Modal>


                        <Modal className="model-dialogadd" title="Add" open={isAddL3MolOpen}
                               onOk={() => {
                                   setAdminLevel3()
                               }}
                               onCancel={() => {
                                   setAddL3Open(false)
                               }}>
                            <Form form={form} name="control-hooks" className="form">
                                {addL3Arr.map((item, index) => {
                                    return (
                                        <Form.Item
                                            name={"address"
                                                + index
                                            }
                                            validateTrigger="onBlur"
                                            label="Address"
                                            validateFirst={true}
                                        >
                                            <div className="input-box">
                                                <Input type="text"></Input>
                                            </div>
                                        </Form.Item>
                                    )
                                })}
                                <div className="icon-box">
                                    <img width={30} src={add} onClick={() => {
                                        addOneL3()
                                    }}/>
                                    <img width={30} src={cut} onClick={() => {
                                        removeOneL3()
                                    }}/>

                                </div>
                            </Form>
                        </Modal>

                        <div className="panel-container">
                            <div className='panel-title'>
                                Set Admin Limit Amount({L2SetL3Amount})
                            </div>

                            <Form form={form} name="control-hooks" className="form">

                                <Form.Item
                                    name="adminL3Amount"
                                    label="New Amounts"
                                    validateTrigger="onBlur"
                                    validateFirst={true}
                                >
                                    <Input/>
                                </Form.Item>
                                <Button type="primary" className="go-btn" onClick={() => {
                                    setAdminL3Amount()
                                }}>
                                    Confirm
                                </Button>
                            </Form>
                        </div>
                        <div>
                            <div className='panel-title'>
                                Set L2 Admin
                            </div>

                            <div className='white-list-box'>
                                <div className='list-header white-header'>
                                    <div>
                                        <div className=' no ' style={{width: "100px"}}>
                                            No.
                                        </div>
                                    </div>
                                    <div className='col'>
                                        Address
                                    </div>

                                </div>
                                {L2AdminArr.map((item, index) => {
                                    return (<div className='list-item white-item' key={index} style={{display: "flex"}}>
                                        <div className=' no ' style={{width: "100px"}}>
                                            {index + 1}
                                        </div>
                                        <div className='col address'>
                                            <a style={{fontSize: "12px"}} onClick={() => {
                                                setIsUpdateOpen(true)
                                                setCurUpdateAddress(item)
                                            }}>
                                                {item}
                                            </a>
                                        </div>
                                    </div>)
                                })}
                                <div className='btn-box'>
                                    <div className='addsbt' onClick={() => {
                                        setAddL2Open(true)
                                    }}>Add
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div>
                            {/*<div className="panel-container">*/}
                            {/*    <div className='panel-title'>*/}
                            {/*        Whitelist Limit Amount({L3owner})*/}
                            {/*    </div>*/}

                            {/*    <Form form={form} name="control-hooks" className="form">*/}

                            {/*        <Form.Item*/}
                            {/*            name="L3Amount"*/}
                            {/*            label="New Amounts"*/}
                            {/*            validateTrigger="onBlur"*/}
                            {/*            validateFirst={true}*/}
                            {/*        >*/}
                            {/*            <Input/>*/}
                            {/*        </Form.Item>*/}
                            {/*        <Button type="primary" className="go-btn" onClick={() => {*/}
                            {/*            setadminL3AddWlAmount()*/}
                            {/*        }}>*/}
                            {/*            Confirm*/}
                            {/*        </Button>*/}
                            {/*    </Form>*/}
                            {/*</div>*/}
                            {/*<div className='superdao-list-box white-list-box'>*/}

                            {/*    <div className='list-header white-header'>*/}
                            {/*        <div className='col'>*/}
                            {/*            No.*/}
                            {/*        </div>*/}
                            {/*        <div className='col'>*/}
                            {/*            Address*/}
                            {/*        </div>*/}
                            {/*        /!*<div className='col'>*!/*/}
                            {/*        /!*    Delete*!/*/}
                            {/*        /!*</div>*!/*/}
                            {/*    </div>*/}
                            {/*    {L3AdminArr.map((item, index) => {*/}
                            {/*        return (<div className='list-item white-item' key={index} style={{display: "flex"}}>*/}
                            {/*            <div className='col no'>*/}
                            {/*                {index + 1}*/}
                            {/*            </div>*/}
                            {/*            <div className='col address'>*/}
                            {/*                <a>*/}
                            {/*                    {item}*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*            /!*<div className="col sc1">*!/*/}
                            {/*            /!*    <img src={sc} className="sc" id='scc' onClick={() => {*!/*/}
                            {/*            /!*        setDelOpen(true)*!/*/}
                            {/*            /!*        setCurDelAddr(item)*!/*/}
                            {/*            /!*    }}/>*!/*/}
                            {/*            /!*</div>*!/*/}

                            {/*        </div>)*/}
                            {/*    })}*/}
                            {/*    /!*<div className='btn-box'>*!/*/}
                            {/*    /!*    <div className='addsbt' onClick={() => {*!/*/}
                            {/*    /!*        setAddL3Open(true)*!/*/}
                            {/*    /!*    }}>Add*!/*/}
                            {/*    /!*    </div>*!/*/}

                            {/*    /!*</div>*!/*/}

                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="panel-container">
                        <div className='panel-title'>
                            setBaseURI({baseURI})
                        </div>

                        <Form form={form} name="control-hooks" className="form">

                            <Form.Item
                                name="BaseURI"
                                label="BaseURI"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Button type="primary" className="go-btn" onClick={() => {
                                setBaseURI()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>
                    <div className="panel-container">
                        <div className='panel-title'>
                            Deposit Token({pointBalance}Point)
                        </div>

                        <Form form={form} name="control-hooks" className="form">

                            <Form.Item
                                name="depositAmount"
                                label="depositAmount"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Button type="primary" className="go-btn" onClick={() => {
                                depositAmount()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                        <div className='panel-title'>
                            Withdraw Token
                        </div>

                        <Form form={form} name="control-hooks" className="form">

                            {/*<Form.Item*/}
                            {/*    name="withdrawAmount"*/}
                            {/*    label="withdrawAmount"*/}
                            {/*    validateTrigger="onBlur"*/}
                            {/*    validateFirst={true}*/}
                            {/*>*/}
                            {/*    <Input/>*/}
                            {/*</Form.Item>*/}
                            <Button type="primary" className="go-btn" onClick={() => {
                                withdrawAmount()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>

        </NFTAdminStyle>
    )
}
export default NFTAdmin;