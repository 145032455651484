import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import zh from "./locales/zh.json"
import en from "./locales/en.json"
i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'zh',
    debug: true,
    resources: {
        zh: {
            translation: zh
        },
        en:{
            translation:en
        }
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
