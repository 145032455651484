import erc20Abi from "../abi/erc20.json";
import MintSOL from "../abi/fireOgv2.json";

let CONTRACTS
// public
CONTRACTS = {
    usdt: {address: "0x55d398326f99059fF775485246999027B3197955", abi: erc20Abi},
    Point: {address: "0xdC299373aC8d1e12a574DF1399234f58e13Bc6Fc", abi: erc20Abi},
    MintSOL: {address: "0xBacFf78e9856F115420cF5Ab477E2abe770F1796", abi: MintSOL},
};
// bnb test
// CONTRACTS = {
//     usdt: {address: "0xC89C114d3A66cbbBbe7B1F630FD1A36bf7Fd992f", abi: erc20Abi},
//     Point: {address: "0x086Efbdc989760218526a022d1787821bc63D96E", abi: erc20Abi},
//     MintSOL: {address: "0x587e192C1927B48fD97982fa57e7B684e828227b", abi: MintSOL},
//
// };
export default CONTRACTS
