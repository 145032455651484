import React, { useState } from 'react';
import { Component, useEffect, useReducer } from "react";
import logo from "../../imgs/logo.png"
import icon1 from "../../imgs/11.png"
import icon2 from "../../imgs/22.png"
import icon3 from "../../imgs/33.png"
import icon4 from "../../imgs/44.png"
import icon5 from "../../imgs/55.png"
import icon6 from "../../imgs/66.png"
import icon7 from "../../imgs/77.png"
import icon8 from "../../imgs/88.png"
import icon9 from "../../imgs/99.png"
import { useLocation, useNavigate } from "react-router-dom";
import footerMap from "../../config/footerMap";
import { Menu } from 'antd';
import FireDAOFooterStyle from "./FireDAOFooterStyle";
function getItem(label, key, hr, children, type) {
    return {
        key,
        hr,
        children,
        label,
        type,
    };
}

const items = footerMap;
const FireDAOFooter = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState(["Holy Fire Altar", "MintPassport"]);
    const [selectNav, setSelectNav] = useState("Holy Fire Altar");
    const [openKeys, setOpenKeys] = useState(['Holy Fire Altar']);
    const rootSubmenuKeys = footerMap.map(items => {
        return items.key
    });
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    const history = useNavigate();
    const location = useLocation()
    const goPage = (obj) => {
        setSelectedKeys(obj.keyPath)
        history("/" + obj.key);
        // setSelectNav(obj.keyPath[1])
    }
    const onOpenChange = (keys) => {
        console.log(keys)
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    useEffect(() => {

        let keyPath = []
        const curKey = location.pathname.substring(1, location.pathname.length)
        footerMap.forEach(navObj => {
            navObj.children.forEach(async nav => {
                if (nav.key == curKey) {
                    await setSelectNav(navObj.key)
                    setOpenKeys([navObj.key])
                    keyPath.push(navObj.key)
                    keyPath.push(nav.key)
                    setSelectedKeys(keyPath)
                }
            })
        })
    }, [])

    return (
        <FireDAOFooterStyle>
         <div className="footer-link">
             www.fdao.club
         </div>
        </FireDAOFooterStyle>
    )

}
export default FireDAOFooter
