import styled from "styled-components";
export default   styled.div`

  .ant-btn-primary::after {
    background: #140E0E;
  }

  .ant-dropdown-menu-item {
    .ant-dropdown-menu-item-only-child {
      background-color: hsla(0, 14%, 12%, 1) !important;
    }
  }

  .ant-dropdown-menu-item
  .ant-dropdown-menu-item-active
  .ant-dropdown-menu-item:hover {
    background-color: hsla(0, 14%, 12%, 1) !important;
  }


  .ant-dropdown-menu-item:hover {
    background-color: hsla(0, 14%, 12%, 1) !important;
  }

  @media screen and (min-width: 1950px) {
    .ant-btn-primary {
      background: #4A52FF;
    }

    .but2 {
      display: block;
    }

    .but1 {
      display: none;
    }

    .ant-btn .ant-btn-primary {
      border: 1px solid rgba(255, 255, 255, 0.15);
    }

    .ant-dropdown-menu-submenu-title:hover {
      background-color: rgb(138, 128, 128);
    }

    .ant-dropdown-menu-item-only-child {
      padding: 0px;
    }

    .ant-dropdown-menu-item {
      .ant-dropdown-menu-item-active >
      .ant-dropdown-menu-item-only-child {
        padding: 0px;
        background-color: hsl(0deg 0% 12.16%);
      }
    }

    .connect-button {
      border-radius: 25px;
      background: #4A52FF;
      border: none;
      span{
        color: #fff!important;
      }
    }

  }
  @media screen and (max-width: 1950px) {
    .ant-btn-primary {
      background: #4A52FF;
      border-radius: 25px;
    }

    .but2 {
      display: block;
    }

    .but1 {
      display: none;
    }

    .ant-dropdown-menu-item {
      overflow: hidden;
    }

    .ant-btn .ant-btn-primary {
      border: 1px solid rgba(255, 255, 255, 0.15);
    }

    .ant-dropdown-menu-submenu-title:hover {
      background-color: rgb(138, 128, 128);
    }

    .ant-dropdown-menu-item-only-child {
      padding: 0px;
    }

    .ant-dropdown-menu-item {
      .ant-dropdown-menu-item-active >
      .ant-dropdown-menu-item-only-child {
        padding: 0px;
        background-color: hsl(0deg 0% 12.16%);
      }
    }

    .connect-button {
      border-radius: 25px;
      background: #4A52FF;
      border: none;
      span{
        color: #fff!important;
      }
    }
  }

  @media screen and (max-width: 1500px) {
    .ant-btn-primary {
      background: #4A52FF;
    }

    .ant-btn ant-btn-primary {
      border: 1px solid rgba(255, 255, 255, 0.15);
    }

    .but2 {
      display: block;
    }

    .but1 {
      display: none;
    }

    .ant-dropdown-menu-item-only-child {
      padding: 0px;
    }

    .ant-dropdown-menu-item {
      .ant-dropdown-menu-item-active >
      .ant-dropdown-menu-item-only-child {
        padding: 0px;
        background-color: hsl(0deg 0% 12.16%);
      }
    }

    .ant-dropdown-menu-submenu-title:hover {
      background-color: rgb(138, 128, 128);
    }

    .connect-button {
      border-radius: 25px;
      background: #4A52FF;
      border: none;
      span {
        color: #fff !important;
      }
    }

  }

  @media screen and (max-width: 450px) {


    .ant-btn-primary {
      background: #4A52FF;
      border-radius: 25px;
    }

    .ant-btn ant-btn-primary {
      border: 1px solid rgba(255, 255, 255, 0.15);

    }

    .ant-dropdown {
      display: none;
    }

    .but2 {
      display: none;
    }

    .but1 {
      display: block;
    }

    .ant-dropdown-menu-item-only-child {
      padding: 0px;
    }

    .ant-dropdown-menu-item {
      .ant-dropdown-menu-item-active >
      .ant-dropdown-menu-item-only-child {
        padding: 0px;
        background-color: hsl(0deg 0% 12.16%);
      }
    }

    .connect-button {
      border-radius: 25px;
      background: #4A52FF;
      border: none;
      span{
        color: #fff!important;
      }
    }

  }
  @media screen and (max-width: 400px) {

    .ant-dropdown {
      display: none;
    }

    .but1 {
      display: block;
      border-radius: 25px;
    }

    .but2 {
      display: none;
    }

    .ant-btn-primary {
      background: #4A52FF;
    }

    .ant-btn ant-btn-primary {
      border: 1px solid rgba(255, 255, 255, 0.15);


    }

    .ant-dropdown-menu-item-only-child {
      padding: 0px;
    }

    .ant-dropdown-menu-item {
      .ant-dropdown-menu-item-active >
      .ant-dropdown-menu-item-only-child {
        padding: 0px;
        background-color: hsl(0deg 0% 12.16%);
      }
    }

    .connect-button {
      border-radius: 25px;
      background: #4A52FF;
      border: none;
      span{
        color: #fff!important;
      }
    }

  }


`

