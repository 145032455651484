import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {useConnect} from "../api/contracts";
import {Modal, Button, Radio, Select, Pagination, Form, Input, notification} from 'antd';
import MintImg from "../imgs/MintIMG.jpg"
import {getContractByContract, getContractByName} from "../api/connectContract";
import {dealMethod, viewMethod} from "../utils/contractUtil"
import {useNavigate} from "react-router-dom";

import BigNumber from "bignumber.js";
import judgeStatus from "../utils/judgeStatus";
import addressMap from "../api/addressMap";
import add from "../imgs/add.png";
import cut from "../imgs/remove.png";

const MaxUint256 = BigNumber('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff').toFixed(0);

const MyNFT = (props) => {
    const [form] = Form.useForm();
    const history = useNavigate();
    const goPage = (url) => {
        history(url);
    }
    const MyNFT = styled.div`
      .my-nft {
        width: 80%;
        margin: 100px auto;
        
        .nft-list {
          display: grid;
          grid-template-columns: repeat(5, minmax(130px, 1fr));
          gap: 10px;
          margin: 50px 0;

          .nft-item {
            padding: 5%;
            border-radius: 5%;
            background: #0e0d10;

            img {
              width: 100%;
            }

            .id-box {
              display: flex;
              justify-content: space-between;
              padding: 5px 0;
              font-size: 18px;
            }
          }
        }
        
      }
      @media screen and (max-width: 1000px) {
        .my-nft {
          width: 90%;
          margin: 100px auto;

          .nft-list {
            display: grid;
            grid-template-columns: repeat(2, minmax(130px, 1fr));
            gap: 10px;
            margin: 50px 0;

            .nft-item {
              padding: 5%;
              border-radius: 5%;
              background: #0e0d10;

              img {
                width: 100%;
              }

              .id-box {
                display: flex;
                justify-content: space-between;
                padding: 5px 0;
                font-size: 18px;
              }
            }
          }

        }
      }
    `
    let {state, dispatch} = useConnect();
    const [contract, setContract] = useState(null)
    const [whitelistArr, setWhiteListArr] = useState([])
    const [owner, setOwner] = useState("")
    const [allowance, setAllowance] = useState()
    const [isAdmin, setIsAdmin] = useState(false)
    const [isAdmin1, setIsAdmin1] = useState(false)
    const [isAdmin2, setIsAdmin2] = useState(false)

    const [pageCount, setPageCount] = useState(20)
    const [curPage, setCurPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [isShowWhite, setIsShowWhite] = useState(false)
    const [canMintAMount, setCanMintAMount] = useState(0)
    const [canMintedMount, setCanMintedAMount] = useState(0)
    const [l2canMintedMount, setL2CanMintedAMount] = useState(0)
    const [l3canMintedMount, setL3CanMintedAMount] = useState(0)
    const [myNFT, setMyNFT] = useState([])

    const [selectValue, setSelectValue] = useState(0)

    const [optionsArr, setOptionsArr] = useState([])

    const [initAmount, setInitAmount] = useState()
    const [totalMint, setTotalMint] = useState()


    const [isW, setIsW] = useState(false)

    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };
    const handleViewCoinMethod = async (name, params) => {
        let contractTemp = await getContractByName("usdt", state.api,)
        return viewMethod(contractTemp, state.account, name, params)
    }
    const handleDealCoinMethod = async (name, params) => {
        let contractTemp = await getContractByName("usdt", state.api,)
        return dealMethod(contractTemp, state.account, name, params)
    }
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("MintSOL", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const handleDealMethod2 = async (name, params) => {
        let contractTemp = await getContractByName("MintSOL", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        dealMethod(contractTemp, state.account, name, params)
    }
    const getUserMintIds = async () => {
        const res = await handleViewMethod("getUserMintIds", [state.account])
        setMyNFT(res)
    }
    const getAllowance = async () => {
        const res = await handleViewCoinMethod("allowance", [state.account, addressMap["MintSOL"].address])
        setAllowance(res)
    }
    const getOwner = async () => {
        const Admin = await handleViewMethod("owner", [])
        setOwner(Admin)
    }

    const approve = async () => {
        await handleDealCoinMethod("approve", [addressMap["MintSOL"].address, MaxUint256])
        getAllowance()
    }
    const checkIsNotWhiteListUser = async () => {
        const res = await handleViewMethod("checkIsNotWhiteListUser", [state.account])
        setIsW(res)
    }
    const MintNFT = async () => {
        if (BigNumber(selectValue).gt(canMintAMount - canMintedMount)) {
            openNotification("Excess of the reissuable balance")
            return
        }
        await handleDealMethod2("mintFireOgNft", [selectValue])
        getAllowance()
        getWhiteListCanMintAmount()
        getAmount()

    }
    const onChangePage = async (page) => {
        await setCurPage(page)
    }
    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
    }
    const getAmount = async () => {
        const amount = await handleViewMethod("initAmount", [])
        setInitAmount(amount)
        const res = await handleViewMethod("totalMint", [])
        setTotalMint(res)
    }
    const getisAdmin = async () => {
        const Admin1 = await handleViewMethod("adminLevel2", [state.account])
        const Admin2 = await handleViewMethod("adminLevel3", [state.account])
        setIsAdmin(Admin1 || Admin2)
        setIsAdmin2(Admin2)
        setIsAdmin1(Admin1)
    }
    const getWhiteListCanMintAmount = async () => {
        const res = await handleViewMethod("l1whiteListCanMintAmount", [])
        const res2 = await handleViewMethod("whiteListMintAmount", [state.account])
        const l2 = await handleViewMethod("l2whiteListCanMintAmount", [])
        const l3 = await handleViewMethod("l3whiteListCanMintAmount", [])

        setCanMintedAMount(res2)
        setL2CanMintedAMount(res2)
        setL3CanMintedAMount(res2)
        if(isAdmin1){
            setCanMintAMount(l2)
        }
        if(isAdmin2){
            setCanMintAMount(l3)
        }
        setCanMintAMount(res)
        let tempArr = []
        for (let i = 0; i < (res - res2); i++) {
            tempArr.push({
                label: i + 1,
                value: i + 1
            })
        }
        setOptionsArr(tempArr)
    }


    useEffect(async () => {

        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        getUserMintIds()

    }, [state.account])


    return (
        <MyNFT>
            <div className="my-nft">
                <div className="nft-list">
                    {myNFT.map((item, index) => {
                        return (<div className="nft-item" key={index}>
                            <img src={MintImg}/>
                            <div className="id-box">
                                <div className="left">ID</div>
                                <div className="right">
                                    {item}
                                </div>
                            </div>
                        </div>)
                    })}

                </div>
            </div>
        </MyNFT>
    )
}
export default MyNFT
