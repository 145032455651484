import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../api/contracts";
import BigNumber from "bignumber.js"
import {
    message,
    Form,
    Pagination, Input, Modal
} from 'antd';

import {getContractByName, getContractByContract} from "../../api/connectContract";
import {dealMethod, dealPayMethod, viewMethod} from "../../utils/contractUtil"

import {useLocation, useNavigate} from "react-router-dom";
import judgeStatus from "../../utils/judgeStatus";

import sc from "../../imgs/sc.png"
import cut from "../../imgs/remove.png"
import add from "../../imgs/add.png"
import NFTAdminStyle from './NFTAdminStyle';


const NFTAdmin = (props) => {
    let {state, dispatch} = useConnect();
    const location = useLocation()

    const [L4AdminArr, setL4AdminArr] = useState([])

    const [L3AdminArr, setL3AdminArr] = useState([])
    const [isDelMolOpen, setDelOpen] = useState(false)
    const [isDelL3MolOpen, setDelL3Open] = useState(false)
    const [isDelL4MolOpen, setDelL4Open] = useState(false)

    const [curDelAddr, setCurDelAddr] = useState()
    const [curDelL3Addr, setCurDelL3Addr] = useState()

    const [curDelL4Addr, setCurDelL4Addr] = useState()

    const [curLevel, setCurLevel] = useState()
    const [isAddMolOpen, setAddOpen] = useState(false)
    const [isAddL2MolOpen, setAddL2Open] = useState(false)
    const [isAddL3MolOpen, setAddL3Open] = useState(false)
    const [isAddActionMolOpen, setAddActionOpen] = useState(false)

    const history = useNavigate();
    const [form] = Form.useForm();

    const [isAdminL3, setIsAdminL3] = useState()
    const [isAdminL2, setIsAdminL2] = useState()

    const [isAdminL4, setIsAdminL4] = useState()

    const [isW, setIsW] = useState([])

    const [whitelistArr, setWhiteListArr] = useState([])

    const [activeArr, setActiveArr] = useState([])

    const [adminArr, setAdminArr] = useState([])
    const [whitelistAmount, setWhitelistAmount] = useState()

    const [adminL3AdminAmount, setadminL3AdminAmount] = useState()
    const [owner, setOwner] = useState("")
    const [initAmount, setInitAmount] = useState()
    const [mintedArr, setMintedArr] = useState([])
    const [recommendRatio, setRecommendRatio] = useState()

    const [activationArr, setActivationArr] = useState([{}])

    const [addWhiteArr, setAddWArr] = useState([{}])
    const [addL2Arr, setL2WArr] = useState([{}])
    const [addL3Arr, setL3WArr] = useState([{}])
    const addOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.push({})
        setAddWArr(addWhiteArrT)
    }
    const removeOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.shift()
        setAddWArr(addWhiteArrT)
    }


    const addOneAction = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(activationArr))
        addWhiteArrT.push({})
        setActivationArr(addWhiteArrT)
    }
    const removeOneAction = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(activationArr))
        addWhiteArrT.shift()
        setActivationArr(addWhiteArrT)
    }

    const getL3List = async () => {
        const res = await handleViewMethod("getL2UnderL3Users", [state.account])
        setL3AdminArr(res)
    }
    const addOneL2 = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addL2Arr))
        addWhiteArrT.push({})
        setL2WArr(addWhiteArrT)
    }
    const removeOneL2 = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addL2Arr))
        addWhiteArrT.shift()
        setL2WArr(addWhiteArrT)
    }

    const addOneL3 = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addL3Arr))
        addWhiteArrT.push({})
        setL3WArr(addWhiteArrT)
    }
    const removeOneL3 = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addL3Arr))
        addWhiteArrT.shift()
        setL3WArr(addWhiteArrT)
    }
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("MintSOL", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("MintSOL", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }
    const setrecommendRatio = async () => {

        await handleDealMethod("setrecommendRatio", [form.getFieldValue().recommendRatio * 100])
        getRecommendRatio()
    }
    const setReceiver = async () => {

        await handleDealMethod("setReceiver", [form.getFieldValue().Receiver])
    }
    const setadminL2AddWlAmount = async () => {

        await handleDealMethod("setadminL2AddWlAmount", [form.getFieldValue().L2Amount])
        getL2AMount()
    }
    const setadminL3AddWlAmount = async () => {
        await handleDealMethod("setadminL3AddWlAmount", [form.getFieldValue().L3Amount])
        getL3AMount()
    }
    const transferOwnership = async () => {

        await handleDealMethod("transferOwnership", [form.getFieldValue().address])
        getOwner()
    }
    const getOwner = async () => {
        const Admin = await handleViewMethod("owner", [])
        setOwner(Admin)
    }
    const getL2AMount = async () => {
        const Admin = await handleViewMethod("adminL3AdminAmount", [])
        setadminL3AdminAmount(Admin)
    }
    const getRecommendRatio = async () => {
        const res = await handleViewMethod("recommendRatio", [])
        setRecommendRatio(res)
    }
    const getL3AMount = async () => {
        const Admin = await handleViewMethod("adminL3AddWlAmount", [])
        setWhitelistAmount(Admin)
    }
    const getAdmin = async () => {

        let adminArr = []
        for (let i = 0; i < 7; i++) {
            const Admin = await handleViewMethod("owner", [])
            adminArr.push(Admin)
        }
        setAdminArr(adminArr)
    }
    const getactivatedUsers = async () => {
        const res = await handleViewMethod("getactivatedUsers", [state.account])
        setActiveArr(res)
    }
    const getInitAmount = async () => {
        let initamount = 0, amount = 0
        initamount = await handleViewMethod("initAmount", [])
        amount = await handleViewMethod("totalMint", [])
        setInitAmount(initamount)
        setMintedArr(amount)

    }
    const getWiteList = async () => {
        const Admin2 = await handleViewMethod("isNotL2Address", [state.account])
        const Admin3 = await handleViewMethod("isNotL3Address", [state.account])
        const Admin4 = await handleViewMethod("isNotL4Address", [state.account])
        if (Admin2) {
            getL3List()

        }
        if (Admin3) {
            const res = await handleViewMethod("getL3UnderL4Users", [state.account])
            setL4AdminArr(res)
        }

        setIsAdminL4(Admin4)
        setIsAdminL3(Admin3)
        setIsAdminL2(Admin2)
    }


    const handleSetInitAmount = async () => {
        await handleDealMethod("setInitAmount", [form.getFieldValue().InitAmount])
        getInitAmount()
    }

    const addWhiteListUser = async () => {
        let arr = []
        for (let i = 0; i < addWhiteArr.length; i++) {
            arr.push(form.getFieldValue()["address" + i])
            const res = await handleViewMethod("adminLevel3", [form.getFieldValue()["address" + i]])
            if (res) {
                message.warn(form.getFieldValue()["address" + i] + "address is in l3")
                return
            }
        }
        await handleDealMethod("addWhiteListUser", [arr])
        getWiteList()
    }

    const setUserActivationStatus = async () => {
        let arr = []
        for (let i = 0; i < activationArr.length; i++) {
            arr.push(form.getFieldValue()["actionAddress" + i])
        }
        await handleDealMethod("setUserActivationStatus", [arr])
        getWiteList()
        getactivatedUsers()
    }
    const setAdminLevel4 = async () => {
        let arr = []
        for (let i = 0; i < addWhiteArr.length; i++) {
            const isIn = await handleViewMethod("isNotL4Address", [form.getFieldValue()["address" + i]])
            if(isIn){
                message.warn(form.getFieldValue()["address" + i]+"address is in list", 5)
                continue;
            }
            arr.push(form.getFieldValue()["address" + i])
        }
        await handleDealMethod("setAdminLevel4", [arr])
        getWiteList()
    }
    const setAdminLevel2 = async () => {
        let arr = []
        for (let i = 0; i < addL2Arr.length; i++) {
            const isIn = await handleViewMethod("isNotL2Address", [form.getFieldValue()["address" + i]])
            if(isIn){
                message.warn(form.getFieldValue()["address" + i]+"address is in list", 5)
                continue;
            }
            arr.push(form.getFieldValue()["address" + i])
        }
        await handleDealMethod("setAdminLevel2", [arr, true])
        getWiteList()
    }
    const checkIsNotWhiteListUser = async () => {
        const res = await handleViewMethod("checkIsNotWhiteListUser", [state.account])
        setIsW(res)
    }
    const setAdminLevel3 = async () => {
        let arr = []
        for (let i = 0; i < addL3Arr.length; i++) {

            const isIn = await handleViewMethod("isNotL3Address", [form.getFieldValue()["address" + i]])
            if(isIn){
                message.warn(form.getFieldValue()["address" + i]+"address is in list", 5)
                continue;
            }
            arr.push(form.getFieldValue()["address" + i])

        }

        await handleDealMethod("setAdminLevel3", [arr])
        getWiteList()
    }

    const removeFromWhiteList = async () => {
        await handleDealMethod("removeFromWhiteList", [[curDelAddr]])
        getWiteList()
    }
    const removeL3FromWhiteList = async () => {
        await handleDealMethod("setAdminLevel3", [[curDelL3Addr]])
        getWiteList()
    }
    const removeL4FromWhiteList= async () => {
        await handleDealMethod("setAdminLevel4", [[curDelL4Addr]])
        getWiteList()
        setTimeout(()=>{
            getWiteList()
        },3000)
    }
    useEffect(async () => {

        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        getOwner()
        getAdmin()
        getInitAmount()
        getWiteList()
        getL2AMount()
        // getL3AMount()
        getRecommendRatio()
        getactivatedUsers()
    }, [state.account])


    return (

        <NFTAdminStyle>
            <div className="part1">
                <Modal className="model-dialogadd" title="Add" open={isAddActionMolOpen}
                       onOk={() => {
                           setUserActivationStatus()
                       }}
                       onCancel={() => {
                           setAddActionOpen(false)
                       }}>
                    <Form form={form} name="control-hooks" className="form">
                        {addWhiteArr.map((item, index) => {
                            return (
                                <Form.Item
                                    name={"actionAddress"
                                        + index
                                    }
                                    validateTrigger="onBlur"
                                    label="Address"
                                    validateFirst={true}
                                >
                                    <div className="input-box">
                                        <Input type="text"></Input>
                                    </div>
                                </Form.Item>
                            )
                        })}
                        <div className="icon-box">
                            <img width={30} src={add} onClick={() => {
                                addOneAction()
                            }}/>
                            <img width={30} src={cut} onClick={() => {
                                removeOneAction()
                            }}/>

                        </div>
                    </Form>
                </Modal>
                <Modal className="model-dialogadd" title="Add" open={isAddMolOpen}
                       onOk={() => {
                           setAdminLevel4()
                       }}
                       onCancel={() => {
                           setAddOpen(false)
                       }}>
                    <Form form={form} name="control-hooks" className="form">
                        {addWhiteArr.map((item, index) => {
                            return (
                                <Form.Item
                                    name={"address"
                                        + index
                                    }
                                    validateTrigger="onBlur"
                                    label="Address"
                                    validateFirst={true}
                                >
                                    <div className="input-box">
                                        <Input type="text"></Input>
                                    </div>
                                </Form.Item>
                            )
                        })}
                        <div className="icon-box">
                            <img width={30} src={add} onClick={() => {
                                addOneWhite()
                            }}/>
                            <img width={30} src={cut} onClick={() => {
                                removeOneWhite()
                            }}/>

                        </div>
                    </Form>
                </Modal>
                <Modal className="model-dialogadd" title="Add" open={isAddL2MolOpen}
                       onOk={() => {
                           setAdminLevel2()
                       }}
                       onCancel={() => {
                           setAddL2Open(false)
                       }}>
                    <Form form={form} name="control-hooks" className="form">
                        {addL2Arr.map((item, index) => {
                            return (
                                <Form.Item
                                    name={"address"
                                        + index
                                    }
                                    validateTrigger="onBlur"
                                    label="Address"
                                    validateFirst={true}
                                >
                                    <div className="input-box">
                                        <Input type="text"></Input>
                                    </div>
                                </Form.Item>
                            )
                        })}
                        <div className="icon-box">
                            <img width={30} src={add} onClick={() => {
                                addOneL2()
                            }}/>
                            <img width={30} src={cut} onClick={() => {
                                removeOneL2()
                            }}/>

                        </div>
                    </Form>
                </Modal>


                <Modal className="model-dialogadd" title="Add" open={isAddL3MolOpen}
                       onOk={() => {
                           setAdminLevel3()
                       }}
                       onCancel={() => {
                           setAddL3Open(false)
                       }}>
                    <Form form={form} name="control-hooks" className="form">
                        {addL3Arr.map((item, index) => {
                            return (
                                <Form.Item
                                    name={"address"
                                        + index
                                    }
                                    validateTrigger="onBlur"
                                    label="Address"
                                    validateFirst={true}
                                >
                                    <div className="input-box">
                                        <Input type="text"></Input>
                                    </div>
                                </Form.Item>
                            )
                        })}
                        <div className="icon-box">
                            <img width={30} src={add} onClick={() => {
                                addOneL3()
                            }}/>
                            <img width={30} src={cut} onClick={() => {
                                removeOneL3()
                            }}/>

                        </div>
                    </Form>
                </Modal>
                <Modal className="model-dialogdel" title="Delete" open={isDelL3MolOpen}
                       onOk={() => {
                           removeL3FromWhiteList()
                       }}
                       onCancel={() => {
                           setDelL3Open(false)
                       }}>
                    <p>
                        Wallet Address
                    </p>
                    <div className="value">
                        {curDelL3Addr}
                    </div>
                </Modal>
                <Modal className="model-dialogdel" title="Delete" open={isDelL4MolOpen}
                       onOk={() => {
                           removeL4FromWhiteList()
                       }}
                       onCancel={() => {
                           setDelL4Open(false)
                       }}>
                    <p>
                        Wallet Address
                    </p>
                    <div className="value">
                        {curDelL4Addr}
                    </div>
                </Modal>
                <Modal className="model-dialogdel" title="Delete" open={isDelMolOpen}
                       onOk={() => {
                           removeFromWhiteList()
                       }}
                       onCancel={() => {
                           setDelOpen(false)
                       }}>
                    <p>
                        Wallet Address
                    </p>
                    <div className="value">
                        {curDelAddr}
                    </div>
                </Modal>

                {isAdminL4 && <div className='panel-box'>
                    <div className='panel-container1'>

                        <div>
                            <div className='panel-title'>
                                Set Activate User
                            </div>

                            <div className='superdao-list-box white-list-box'>
                                <div className='list-header white-header'>
                                    <div className='col'>
                                        No.
                                    </div>
                                    <div className='col'>
                                        Address
                                    </div>
                                </div>
                                {activeArr.map((item, index) => {
                                    return (<div className='list-item white-item' key={index} style={{display: "flex"}}>
                                        <div className='col no'>
                                            {index + 1}
                                        </div>
                                        <div className='col address'>
                                            <a>
                                                {item}
                                            </a>
                                        </div>

                                    </div>)
                                })}
                                <div className='btn-box'>
                                    <div className='addsbt' onClick={() => {
                                        setAddActionOpen(true)
                                    }}>Add
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>}
                {isAdminL3 && <div className='panel-box'>
                    <div className='panel-container1'>

                        <div>
                            <div className='panel-title'>
                                Set Admin L4
                            </div>
                            <div className='panel-title'>
                                Total can set {adminL3AdminAmount}, you can set {adminL3AdminAmount - L4AdminArr.length} left.
                            </div>

                            <div className='superdao-list-box white-list-box'>
                                <div className='list-header white-header'>
                                    <div className='col'>
                                        No.
                                    </div>
                                    <div className='col'>
                                        Address
                                    </div>
                                    <div className='col'>
                                        Delete
                                    </div>
                                </div>
                                {L4AdminArr.map((item, index) => {
                                    return (<div className='list-item white-item' key={index} style={{display: "flex"}}>
                                        <div className='col no'>
                                            {index + 1}
                                        </div>
                                        <div className='col address'>
                                            <a>
                                                {item}
                                            </a>
                                        </div>
                                        <div className="col sc1">
                                            <img src={sc} className="sc" id='scc' style={{cursor: "pointer"}}
                                                 onClick={() => {
                                                     setDelL4Open(true)
                                                     setCurDelL4Addr(item)
                                                 }}/>
                                        </div>

                                    </div>)
                                })}
                                <div className='btn-box'>
                                    <div className='addsbt' onClick={() => {
                                        setAddOpen(true)
                                    }}>Add
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>}
                {isAdminL2 && <div className="panel-box">
                    <div className="panel-title">
                        Set Admin L3
                    </div>
                    <div className="panel-title">
                        Total can set {adminL3AdminAmount}, you can set {adminL3AdminAmount - L3AdminArr.length} left.
                    </div>
                    <div className="panel-container1">
                        <div className='superdao-list-box white-list-box'>

                            <div className='list-header white-header'>
                                <div className='col'>
                                    No.
                                </div>
                                <div className='col'>
                                    Address
                                </div>

                            </div>
                            {L3AdminArr.map((item, index) => {
                                return (<div className='list-item white-item' key={index} style={{display: "flex"}}>
                                    <div className='col no'>
                                        {index + 1}
                                    </div>
                                    <div className='col address'>
                                        <a>
                                            {item}
                                        </a>
                                    </div>
                                    <div className="col sc1">
                                        <img src={sc} className="sc" id='scc' style={{cursor: "pointer"}}
                                             onClick={() => {
                                                 setDelL3Open(true)
                                                 setCurDelL3Addr(item)
                                             }}/>
                                    </div>

                                </div>)
                            })}
                            <div className='btn-box'>
                                <div className='addsbt' onClick={() => {
                                    setAddL3Open(true)
                                }}>Add
                                </div>

                            </div>

                        </div>
                    </div>
                </div>}
            </div>

        </NFTAdminStyle>
    )
}
export default NFTAdmin;