import {fetchQueryBase} from "./index";
// https://api.studio.thegraph.com/query/45388/0dd7/v0.0.1
export function getTeamRecordOne(addr) {
    return fetchQueryBase("45388/e515a/v0.0.1", {
        text: `{
         inviteRewards(where:{one: "${addr}"}, first: 1000){
          user
          one
          oneReward
          onePoint
          two
          twoReward
          twoPoint
          blockTimestamp
        }
        }`
    }, "")
}
export function getTeamRecordTwo(addr) {
    return fetchQueryBase("45388/e515a/v0.0.1", {
        text: `{
         inviteRewards(where:{two: "${addr}"}, first: 1000){
          user
          one
          oneReward
          onePoint
          two
          twoReward
          twoPoint
          blockTimestamp
        }
        }`
    }, "")
}


